import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { useEffect } from 'react';
import { Wrap } from '../../../components/sections-wrap';
import { Index } from '../../../components/tabs/tab-index';

import * as styles from './index.module.scss';

function BrowseTopics({ data }) {
  const tagsWithContent = data?.allWpTag?.nodes.filter((item) => !!item.contentNodes.nodes.length);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={styles.browseContainer}>
      <title>Browse legal topics - Robinson and Henry</title>
      <Wrap>
        <div className={styles.header}>
          <h1 className={styles.title}>Browse by Legal Issues</h1>
        </div>
        <Index data={tagsWithContent} />
      </Wrap>
    </div>
  );
}

export const query = graphql`
  {
    allWpTag(sort: {order: ASC, fields: name}) {
      nodes {
        name
        uri
        contentNodes {
          nodes {
            uri
          }
        }
      }
    }
  }
`;

BrowseTopics.propTypes = {
  data: object,
};

BrowseTopics.defaultProps = {
  data: {},
};

export default BrowseTopics;
