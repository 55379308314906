import { useState, useEffect } from 'react';
import { array } from 'prop-types';
import * as styles from './tabs.module.scss';

function Index({ data }) {
  const [dataIndex, setDataIndex] = useState(false);
  const [letterSelected, setLetterSelected] = useState('');
  const alphabet = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

  useEffect(() => {
    let fstLetter = 'A';
    let arrayAux = [];
    const dataStructured = [];
    data.forEach((elem) => {
      const elemFstLetter = elem.name[0].toUpperCase();
      if (alphabet.includes(elemFstLetter)) {
        if (elemFstLetter === fstLetter) {
          arrayAux.push({ name: elem.name, uri: elem.uri });
        } else {
          dataStructured[fstLetter] = arrayAux;
          fstLetter = elemFstLetter;
          arrayAux = [];
          arrayAux.push({ name: elem.name, uri: elem.uri });
        }
      }
    });
    dataStructured[fstLetter] = arrayAux;
    setDataIndex(dataStructured);
  }, []);
  return (
    <div className={styles.containerBrowseIndex}>
      <div>
        <ul className={styles.index}>
          { alphabet.map((letter) => (
            <li className={styles.itemList}>
              {' '}
              {dataIndex[letter]?.length > 0 ? (
                <a
                  className={letterSelected === letter ? styles.letterActive
                    : styles.letterHasContent}
                  href={`#${letter}`}
                  onClick={() => setLetterSelected(letter)}
                >
                  {letter}
                </a>
              ) : <span>{ letter }</span>}
              {' '}
            </li>
          )) }
        </ul>
      </div>
      {' '}
      {alphabet.map((letter) => (
        dataIndex[letter]?.length > 0
        && (
        <div className={styles.content}>
          <h2 id={letter} className={styles.letter}>{letter}</h2>
          <ul className={styles.list}>
            {dataIndex[letter]?.map((item) => (
              <li className={styles.itemList} key={item.uri}>
                <a className={styles.item} href={item.uri}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        )
      ))}
    </div>
  );
}

Index.propTypes = {
  data: array.isRequired,
};

export { Index };
